import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Card from 'react-bootstrap/Card'

export const seo = {
  title: 'About Us | Crampete',
  description: 'Crampete is a privately held EdTech company headquartered in Singapore and having offices in India and Malaysia. We offer training for in-demand tech skills. Learn more.',
  keywords: 'Crampete, About Us',
  image: '',
  url: 'https://www.crampete.com/about',
  author: 'Crampete'
}

export default () => (
  <Layout title={seo.title} description={seo.description} image={seo.image} keywords={seo.keywords} url={seo.url} author={seo.author}>
  <div className="headerShadow"></div>
    <div style={{maxWidth:"800px", margin:"auto"}}>
      <div className="container">
        <div className="row text-center mt-4">
          <div className="col-md-12 mt-4">
            <h1>Who we are?</h1>
            <p>Crampete is a privately held EdTech company headquartered in Singapore with offices in India, Malaysia and Singapore. Crampete is founded and managed by a team of technocrats with more than a million hour combined coding experience.</p>
            <Card className="cardShadow p-0 text-center mt-4" style={{maxWidth:"500px",margin:"auto"}}>
                <img src="https://cdn1.iconfinder.com/data/icons/logotypes/32/youtube-512.png" className="image-youtube-icon" alt="youtube icon" />
                <Link to="/modal-youtube#Wlx_SsSp1i8" className="youtubeSpn" state={{ modal: true, width: "300px"}}>
                  <img className="image-youtube" src="https://i.ytimg.com/vi/Wlx_SsSp1i8/maxresdefault.jpg" alt="youtube video" />
                  <div className="image-youtube-overlay"></div>
                </Link>
            </Card>
          </div>
        </div>
        <div className="row text-center mt-4">
          <div className="col-md-12">
            <h2>What we do?</h2>
            <p>Crampete creates, markets and delivers in-demand tech courses to students and working professionals across the world. Our flagship programs are Full Stack Web Development and Data Science courses that are offered as instructor led live sessions through digital channels and also on-campus where students get to attend classes at our learning centres located in Singapore, Malaysia, India and Middle East currently.</p>
            <p>Cram Code is an AI driven self paced learning platform that helps students and working professional to learn code by doing code anytime, anywhere.</p>
          </div>
        </div>
        <div className="row text-center mt-4">
          <div className="col-md-12 mb-4">
            <h1>Our Vision</h1>
            <p>Our vision is to transform lives by imparting cutting edge tech skills.</p>
          </div>
        </div>
      </div>
    </div>
      <div className="container mb-4 mt-4 pt-4">
        <div className="row">
          <div className="col-md-4">
            <Card className="cardShadow p-0">
                <img src="https://cdn1.iconfinder.com/data/icons/logotypes/32/youtube-512.png" className="image-youtube-icon" alt="youtube crampete" />
                <Link to="/modal-youtube#S7gZADsf_ys" className="youtubeSpn" state={{ modal: true, width: "300px"}}>
                  <img className="image-youtube" src="https://i.ytimg.com/vi/S7gZADsf_ys/maxresdefault.jpg" alt="youtube video" />
                  <div className="image-youtube-overlay"></div>
                </Link>
            </Card>
          </div>
          <div className="col-md-4">
            <Card className="cardShadow p-0">
                <img alt="crampete about" src="https://cdn1.iconfinder.com/data/icons/logotypes/32/youtube-512.png" className="image-youtube-icon" />
                <Link to="/modal-youtube#PSmy_qlgTI4" className="youtubeSpn" state={{ modal: true, width: "300px"}}>
                  <img className="image-youtube" src="https://i.ytimg.com/vi/PSmy_qlgTI4/maxresdefault.jpg" alt="youtube video" />
                  <div className="image-youtube-overlay"></div>
                </Link>
            </Card>
          </div>
          <div className="col-md-4">
              <Card className="cardShadow p-0">
                  <img alt="crampete about" src="https://cdn1.iconfinder.com/data/icons/logotypes/32/youtube-512.png" className="image-youtube-icon" />
                  <Link to="/modal-youtube#jB1CCqD3sj8" className="youtubeSpn" state={{ modal: true, width: "300px"}}>
                    <img className="image-youtube" src="https://i.ytimg.com/vi/jB1CCqD3sj8/maxresdefault.jpg" alt="youtube video" />
                    <div className="image-youtube-overlay"></div>
                  </Link>
              </Card>
          </div>
        </div>
      </div>
    <hr className="container hrLight" style={{marginTop:"50px"}} />
  	<div className="container mt-4 pt-4 d-none">
  		<div className="row ">
  				<div className="col-sm-6 col-lg-6 col-xl-6">
  						<div className="partnerOuter">
  							<h3>Become our Hiring Partner</h3>
  							<p>Access to ambitious, hard-working and committed professionals, rigorously trained by us in the hottest tech tracks – ready to join you.</p>
                <Link className="btnPartner" to="/about"><i className="fa fa-angle-double-right"></i>Hire Now <span className="flaticon-right-arrow-1"></span></Link>
  						</div>
  				</div>
  				<div className="col-sm-6 col-lg-6 col-xl-6">
  						<div className="partnerOuter">
  							<h3>Own a Learning Centre</h3>
  							<p>Contact us if you are interested to start Crampete learning centre in your location. We offer cutting edge tech courses through guided self paced and live streaming.</p>
                <Link className="btnPartner" to="/about"><i className="fa fa-angle-double-right"></i>Enquire Now <span className="flaticon-right-arrow-1"></span></Link>
  					</div>
  				</div>
  			</div>
  	</div>
  </Layout>
)